import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="logo-looperfi.png" style={{width: 220}}/>
      </header>
    </div>
  );
}

export default App;
